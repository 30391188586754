import React from 'react'
import { useDispatch } from 'react-redux'

import { Button, Box, Divider, Typography } from '@material-ui/core'
import {
  Block,
  CancelOutlined as CancelCollect,
  Done,
  DoneAll,
  Edit,
  MenuOpen as OpenCollect,
  Print as PrinCollect,
  Schedule,
} from '@material-ui/icons'

import { ChipStatus } from '@/components'
import { createCollectForPrint } from '@/services/collects'
import formatter from '@/services/formatter'
import {
  cancelCollectRequest,
  displayForEditCollect,
  reOpenCollectRequest,
} from '@/store/modules/users/collections/reducer'

import {
  ButtonsContainer,
  HeaderContainer,
  SummaryBlockContainer,
} from '../styles'

// ------------------------------------------------------------------------

export const ORDER_COLLECT_STATUS = {
  new: 1,
  confirmed: 2,
  loaded: 3,
  cancelled: 4,
}

const Icons = {
  [ORDER_COLLECT_STATUS.new]: <Schedule color="inherit" fontSize="small" />,
  [ORDER_COLLECT_STATUS.confirmed]: <Done color="inherit" fontSize="small" />,
  [ORDER_COLLECT_STATUS.loaded]: <DoneAll color="inherit" fontSize="small" />,
  [ORDER_COLLECT_STATUS.cancelled]: <Block color="inherit" fontSize="small" />,
}

// ------------------------------------------------------------------------

export function IssuedCollectsTableHeader(props) {
  const { data: coleta, onPrint, children } = props

  const { summary } = coleta

  const coletaHeader = coleta.header

  const dispatch = useDispatch()

  function handleReopen() {
    dispatch(reOpenCollectRequest(coletaHeader))
  }

  function handlePrint() {
    onPrint(createCollectForPrint(coletaHeader.id_coleta, coleta.items))
  }

  function handleCancel() {
    dispatch(cancelCollectRequest(coletaHeader))
  }

  function handleEdit() {
    dispatch(displayForEditCollect(coletaHeader))
  }

  const showObservationsSection =
    coletaHeader.vpt_obs?.trim() || coletaHeader.vpt_data_cancelamento

  const isWaitingConfirmation =
    coletaHeader.status?.id === ORDER_COLLECT_STATUS.new

  const isWaitingLoad =
    coletaHeader.status?.id === ORDER_COLLECT_STATUS.confirmed

  const isEditable = [
    ORDER_COLLECT_STATUS.new,
    ORDER_COLLECT_STATUS.confirmed,
  ].includes(coletaHeader.status?.id)

  const driver = formatter(coletaHeader.vpt_cod_motorista).toCPF()

  return (
    <HeaderContainer>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography
          color="textPrimary"
          variant="h6"
          style={{
            fontWeight: 600,
            display: 'flex',
            alignItems: 'center',
            gap: '0.5rem',
          }}
        >
          {coletaHeader.id_coleta}
          {children}
        </Typography>

        <Box display="flex" gridGap="1rem">
          <Typography
            variant="body2"
            component="div"
            style={{
              display: 'flex',
              gap: '0.5rem',
            }}
          >
            <span style={{ fontWeight: 600, textTransform: 'uppercase' }}>
              Emissão:
            </span>

            <span>
              {formatter(coletaHeader.vpt_data_emissao).toSimpleDate()}
            </span>
          </Typography>

          <Typography
            variant="body2"
            component="div"
            style={{
              display: 'flex',
              gap: '0.5rem',
            }}
          >
            <span style={{ fontWeight: 600, textTransform: 'uppercase' }}>
              Coleta:
            </span>

            <span>{formatter(coletaHeader.vpt_dcoleta).toSimpleDate()}</span>
          </Typography>
        </Box>
      </Box>

      <Box display="flex" alignItems="center">
        <Box display="flex" flexDirection="column" gridGap="0.5rem" flex={1}>
          {/* === SUMMARY === */}
          <Box display="flex" alignItems="center" gridGap="1.5rem" mb={1}>
            <SummaryBlock label={driver} value={coletaHeader.vpt_motorista} />

            <SummaryBlock label="Placa" value={coletaHeader.vpt_placa} />

            <Divider orientation="vertical" flexItem />

            <SummaryBlock
              label="Peso"
              value={formatter(summary.weight).toDecimal()}
            />

            <SummaryBlock
              label="Quantidade"
              value={formatter(summary.quantity).toDecimal()}
            />
          </Box>

          {/* === NOTE === */}
          <Box display={showObservationsSection ? 'block' : 'none'}>
            <Typography component="p" variant="body2" gutterBottom>
              {coletaHeader.vpt_obs}
            </Typography>

            {coletaHeader.vpt_data_cancelamento && (
              <Typography component="p" variant="body2" color="error">
                {formatter(coletaHeader.vpt_data_cancelamento).toSimpleDate()} -
                {coletaHeader.vpt_motivo_cancelamento}
              </Typography>
            )}
          </Box>

          {/* === ACTIONS === */}
          <ButtonsContainer>
            {isWaitingLoad && (
              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<PrinCollect fontSize="small" />}
                onClick={handlePrint}
              >
                Imprimir
              </Button>
            )}
            {isWaitingConfirmation && (
              <Button
                variant="contained"
                color="primary"
                size="small"
                className="reopen"
                startIcon={<OpenCollect fontSize="small" />}
                onClick={handleReopen}
              >
                Reabrir
              </Button>
            )}
            {isWaitingConfirmation && (
              <Button
                variant="contained"
                color="primary"
                size="small"
                startIcon={<CancelCollect fontSize="small" />}
                className="cancel"
                onClick={handleCancel}
              >
                Cancelar
              </Button>
            )}

            {isEditable && (
              <Button
                variant="outlined"
                color="primary"
                size="small"
                startIcon={<Edit fontSize="small" />}
                onClick={handleEdit}
              >
                Alterar
              </Button>
            )}
          </ButtonsContainer>
        </Box>

        {coletaHeader.status && (
          <ChipStatus
            icon={Icons[coletaHeader.status.id]}
            label={coletaHeader.status.descr}
            background={coletaHeader.status.color}
            className="large"
            variant="default"
          />
        )}
      </Box>
    </HeaderContainer>
  )
}

// ------------------------------------------------------------------------

function SummaryBlock(props) {
  const { label, value } = props

  return (
    <SummaryBlockContainer>
      <Typography component="span" variant="body2" color="textSecondary">
        {label}
      </Typography>

      <Typography component="span" color="primary">
        {value}
      </Typography>
    </SummaryBlockContainer>
  )
}
