import React from 'react'
import PropTypes from 'prop-types'
import { groupWith, isEmpty } from 'ramda'
import { useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { Table } from '@/components/Table/styles'
import {
  Collapse,
  IconButton,
  TableContainer,
  Tooltip,
} from '@material-ui/core'

import { TableBody, TableCell, TableHead, TableRow } from '@/components/Table'

import { TablePagination } from '@/components/TablePagination'
import { usePagination } from '@/hooks/usePagination'
import { ExpandMore } from '@material-ui/icons'

import { createQueryParameters, sumOf } from '@/services/utils'
import { issuedsRequest } from '@/store/modules/users/collections/reducer'
import { IssuedCollectsTableHeader, ORDER_COLLECT_STATUS } from './Header'

import { TableNoData } from '@/components/Table/TableNoData'
import formatter from '@/services/formatter'

// ------------------------------------------------------------------------

function OrderTable({ coleta, onPrint }) {
  const [open, setOpen] = useState(() => {
    const status = coleta.header.status?.id

    return [ORDER_COLLECT_STATUS.new, ORDER_COLLECT_STATUS.confirmed].includes(
      status
    )
  })

  return (
    <TableBody>
      <TableRow>
        <TableCell>
          <IssuedCollectsTableHeader data={coleta} onPrint={onPrint}>
            <Tooltip
              title={open ? 'Minimizar carregamento' : 'Maximizar carregamento'}
            >
              <IconButton
                color="primary"
                size="small"
                onClick={() => setOpen(!open)}
                style={{
                  transform: open ? 'rotate(180deg)' : 'none',
                  transition: 'transform 100ms ease',
                }}
              >
                <ExpandMore />
              </IconButton>
            </Tooltip>
          </IssuedCollectsTableHeader>
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell colSpan={2}>
          <Collapse unmountOnExit in={open}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>COLETA</TableCell>
                  <TableCell>PEDIDO</TableCell>
                  <TableCell>CLIENTE</TableCell>
                  <TableCell>CIDADE</TableCell>
                  <TableCell>UF</TableCell>
                  <TableCell>PRODUTO</TableCell>
                  <TableCell align="right">PESO</TableCell>
                  <TableCell align="right">PAL</TableCell>
                  <TableCell align="right">CXS</TableCell>
                  <TableCell align="right">QUANTIDADE</TableCell>
                  <TableCell>REPRESENTANTE</TableCell>
                  <TableCell>OC</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {coleta.items.map((item) => {
                  return (
                    <TableRow key={String(item.id)}>
                      <TableCell>{item.id_coleta}</TableCell>
                      <TableCell>{item.cod_pedido}</TableCell>
                      <TableCell>{item.cliente_nome}</TableCell>
                      <TableCell>{item.cidade_cliente}</TableCell>
                      <TableCell>{item.dsc_uf}</TableCell>
                      <TableCell>{item.dsc_abreviado}</TableCell>
                      <TableCell align="right">
                        {formatter(item.vpt_peso_bruto).toDecimal()}
                      </TableCell>
                      <TableCell align="right">{item.vpt_pallet}</TableCell>
                      <TableCell align="right">{item.vpt_qtd_caixas}</TableCell>
                      <TableCell align="right">
                        {formatter(item.vpt_qtd).toDecimal()}
                      </TableCell>
                      <TableCell>{item.rep_nome}</TableCell>
                      <TableCell>{item.ford_numero}</TableCell>
                    </TableRow>
                  )
                })}

                <TableRow
                  style={{
                    backgroundColor: 'rgba(207, 216, 220, 0.15)',
                  }}
                >
                  <TableCell colSpan={6} />

                  <TableCell align="right" style={{ fontWeight: 'bold' }}>
                    {formatter(coleta.summary.weight).toDecimal()}
                  </TableCell>

                  <TableCell align="right" style={{ fontWeight: 'bold' }}>
                    {coleta.summary.amountPallets}
                  </TableCell>

                  <TableCell align="right" style={{ fontWeight: 'bold' }}>
                    {coleta.summary.amountBoxes}
                  </TableCell>

                  <TableCell align="right" style={{ fontWeight: 'bold' }}>
                    {formatter(coleta.summary.quantity).toDecimal()}
                  </TableCell>

                  <TableCell colSpan={2} />
                </TableRow>
              </TableBody>
            </Table>
          </Collapse>
        </TableCell>
      </TableRow>
    </TableBody>
  )
}

// ---------------------------------------------------------------------------
export function IssuedCollectsTable({ onPrintCollect, requestFilters }) {
  const count = useSelector((state) => state.users.collections.issueds.count)
  const data = useSelector((state) => state.users.collections.issueds.issueds)
  const loading = useSelector((state) => state.users.collections.loading)

  const dispatch = useDispatch()

  const { page, rowsPerPage, onChangePage, onChangeRowsPerPage } =
    usePagination({
      initialRowsPerpage: 10,
    })

  useEffect(() => {
    const filters = requestFilters.reduce((acc, item) => {
      const itemValue = item.value?.trim()

      if (acc[item.filterBy]) {
        acc[item.filterBy].push(itemValue)
      } else {
        acc[item.filterBy] = [itemValue]
      }

      return acc
    }, {})

    const queryParameters = createQueryParameters({
      page,
      rowsPerPage,
      filters,
    })

    dispatch(issuedsRequest(queryParameters))
  }, [dispatch, page, rowsPerPage, requestFilters])

  const coletas = useMemo(() => {
    /**
     * @type {unknown[][]}
     */
    const coletas = groupWith(groupById, data)

    return coletas.map((coletaItems) => {
      const sortedColetaItems = coletaItems.sort(
        (a, b) => a.cod_cliente - b.cod_cliente
      )

      return {
        header: sortedColetaItems[0],
        items: sortedColetaItems,
        summary: {
          quantity: sumOf(sortedColetaItems, 'vpt_qtd'),
          weight: sumOf(sortedColetaItems, 'vpt_peso_bruto'),
          amountPallets: sumOf(sortedColetaItems, 'vpt_pallet'),
          amountBoxes: sumOf(sortedColetaItems, 'vpt_qtd_caixas'),
        },
      }
    })
  }, [data])

  return (
    <TableContainer>
      <Table size="medium">
        <TableHead>
          <TableRow>
            <TableCell>COLETA</TableCell>
          </TableRow>
        </TableHead>

        {coletas.map((coleta) => {
          return (
            <OrderTable
              key={coleta.header.id}
              coleta={coleta}
              onPrint={onPrintCollect}
            />
          )
        })}

        <TableBody>
          <TableNoData
            empty={isEmpty(coletas)}
            loading={loading}
            emptyText="Nenhuma coleta encontrada"
            colSpan={12}
          />
        </TableBody>
      </Table>

      <TablePagination
        count={count}
        page={page}
        rowsPerPage={rowsPerPage}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </TableContainer>
  )
}

IssuedCollectsTable.propTypes = {
  onPrintCollect: PropTypes.func,
}

// ------------------------------------------------------------------------

function groupById(a, b) {
  return a.id_coleta_for_order === b.id_coleta_for_order
}
