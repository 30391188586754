export const ORDER_TYPES = {
  NOTA_FISCAL: 'N',
  PEDIDO: 'P',
  MKT_SAC: 'A',
  REMESSA: 'M',
}

export const ORDER_TYPES_LABELS = {
  N: 'NOTA FISCAL',
  P: 'PEDIDO',
  A: 'MKT SAC',
  M: 'REMESSA',
  B: 'BONIFICAÇÃO',
}

export const ORDER_TYPES_ACRONYM = {
  N: 'NF',
  P: 'PE',
  A: 'MKT/SAC',
  M: 'RE',
  B: 'BN',
}

// ----------------------------------------------------------

export const isSelectableOrderItem = (type) =>
  [ORDER_TYPES.REMESSA].includes(type)

export const isEditableOrderItem = (type) =>
  [ORDER_TYPES.REMESSA].includes(type)
