import React, { useEffect, useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { invoicesRequest } from '@/store/modules/users/queries/invoices/reducer'
import { usePagination, useSortData } from '@/hooks'

import { Table as CustomTable, TablePagination } from '@/components'
import { InvoicesTableContainer } from './styles'
import { InvoicesTableDetail } from './detail'
import { groupWith } from 'ramda'

// ---------------------------------------------------

const INVOICES_TABLE_HEAD = [
  { title: 'CARGA', field: 'id_coleta' },
  {
    title: 'MOTORISTA',
    field: 'vpt_motorista',
  },
  { title: 'PLACA', field: 'vpt_placa' },
]

// ---------------------------------------------------

export function InvoicesTable() {
  const dispatch = useDispatch()

  const invoices = useSelector((state) => state.users.queries.invoices.invoices)
  const loading = useSelector((state) => state.users.queries.invoices.loading)

  const { currentSort, onSortChange, sortData } = useSortData({
    initialField: 'id_coleta',
  })

  const {
    page,
    rowsPerPage,
    onChangePage,
    onChangeRowsPerPage,
    reset,
    calculateNewPaginatorData,
  } = usePagination({
    initialPage: 0,
    initialRowsPerpage: 10,
  })

  useEffect(() => {
    dispatch(invoicesRequest())
  }, [dispatch])

  useEffect(() => {
    reset()
  }, [reset])

  const coletas = useMemo(() => {
    /**
     * @type {unknown[][]}
     */
    const coletas = groupWith((a, b) => a.id_coleta === b.id_coleta, invoices)

    return coletas.map((invoices) => ({
      id_coleta: invoices[0].id_coleta,
      vpt_motorista: invoices[0].shipment.vpt_motorista,
      vpt_placa: invoices[0].shipment.vpt_placa,
      invoices,
    }))
  }, [invoices])

  const detailPanel = useCallback((args) => {
    const { row: coleta, show } = args

    return <InvoicesTableDetail invoices={coleta.invoices} show={show} />
  }, [])

  const { currentPageRecords } = calculateNewPaginatorData(sortData(coletas))

  return (
    <InvoicesTableContainer>
      <CustomTable
        loading={loading}
        size="medium"
        data={currentPageRecords}
        columns={INVOICES_TABLE_HEAD}
        currentSort={currentSort}
        onSortChange={onSortChange}
        detailPanel={detailPanel}
      />

      <TablePagination
        count={coletas.length}
        page={page}
        rowsPerPage={rowsPerPage}
        onChangePage={onChangePage}
        onChangeRowsPerPage={onChangeRowsPerPage}
      />
    </InvoicesTableContainer>
  )
}
