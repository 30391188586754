import React from 'react'

import { Content } from '@/components'
import { InvoicesTable } from './Table'
// import { Filter } from './Filter'

// --------------- 𝕄𝕒𝕚𝕟 ---------------

export default function InvoicesPage() {
  return (
    <Content
      showReturn
      title="Consultar Notas Fiscais emitidas"
      // SideComponent={<Filter />}
    >
      <InvoicesTable />
    </Content>
  )
}
