import React, { useState, useCallback } from 'react'
import { CloudDownloadOutlined } from '@material-ui/icons'
import { Box, Collapse } from '@material-ui/core'

import {
  TableCell,
  TableRow,
  Table,
  TableHead,
  TableBody,
} from '@/components/Table/styles'

import { DownloadButton } from './styles'
import { downloadInvoiceDANFE } from '@/api/villagres'

// ---------------------------------------------------

export function InvoicesTableDetail(props) {
  const { invoices, show } = props

  return (
    <TableRow className="detail">
      <TableCell style={{ padding: 0 }} colSpan={4}>
        <Collapse in={show}>
          <Box padding="1rem" bgcolor="background.default">
            <Box bgcolor="background.paper">
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>PEDIDO</TableCell>
                    <TableCell>TIPO</TableCell>
                    <TableCell>CLIENTE</TableCell>
                    <TableCell>CIDADE</TableCell>
                    <TableCell>CHAVE</TableCell>
                    <TableCell>DOWNLOAD</TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {invoices.map((invoice) => {
                    return (
                      <TableRow key={invoice.id}>
                        <TableCell>{invoice.id[0]}</TableCell>
                        <TableCell>{invoice.tipo}</TableCell>
                        <TableCell>{invoice.shipment?.cliente_nome}</TableCell>
                        <TableCell>{invoice.shipment?.cli_cidade}</TableCell>
                        <TableCell>{invoice.chave}</TableCell>
                        <TableCell>
                          <DownloadButtons invoice={invoice} />
                        </TableCell>
                      </TableRow>
                    )
                  })}
                </TableBody>
              </Table>
            </Box>
          </Box>
        </Collapse>
      </TableCell>
    </TableRow>
  )
}

// ---------------------------------------------------

function DownloadButtons({ invoice }) {
  const [loading, setLoading] = useState(false)

  const handleDownloadPDF = useCallback(async () => {
    setLoading(true)

    await downloadInvoiceDANFE(invoice.chave)

    setLoading(false)
  }, [invoice])

  return (
    <Box display="flex" alignItems="center" gridGap="0.5rem">
      <DownloadButton
        size="small"
        color="inherit"
        variant="outlined"
        onClick={handleDownloadPDF}
        disabled={loading}
        startIcon={<CloudDownloadOutlined />}
      >
        PDF
      </DownloadButton>
    </Box>
  )
}
