import api from '@/services/api'
import { toast } from 'react-toastify'

const DOWNLOAD_DANFE_URL = process.env.REACT_APP_DOWNLOAD_DANFE_URL
const DOWNLOAD_DANFE_USER = process.env.REACT_APP_DOWNLOAD_DANFE_USER
const DOWNLOAD_DANFE_PASSWORD = process.env.REACT_APP_DOWNLOAD_DANFE_PASSWORD

export async function downloadInvoiceDANFE(invoiceId) {
  try {
    const formData = new FormData()

    formData.append('user', DOWNLOAD_DANFE_USER)
    formData.append('password', DOWNLOAD_DANFE_PASSWORD)
    formData.append('chvdoe', invoiceId)

    const response = await api.post(DOWNLOAD_DANFE_URL, formData, {
      responseType: 'blob',
      headers: {
        'Content-type': 'application/pdf',
      },
    })

    const file = new Blob([response.data], { type: 'application/pdf' })
    const fileURL = URL.createObjectURL(file)

    window.open(fileURL)
  } catch (error) {
    console.log(error)
    toast.error('Falha ao abrir o DANFE. Tente novamente mais tarde')
  }
}
