import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { isEmpty, groupWith, equals } from 'ramda'
import { colors } from 'theme'
import { extractLens } from 'services/utils'

import { TableSortLabel } from '@material-ui/core'
import {
  Container,
  Table as StyledTable,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableFooter,
} from './styles'

import RenderTableRow from './TableRow'
import { TableNoData } from './TableNoData'

// --------------- 𝕄𝕒𝕚𝕟 ---------------

function Table({
  data,
  columns,
  components,
  groupBy,
  groupHeader,
  cellStyle,
  rowStyle,
  loading,
  currentSort,
  onSortChange,
  stripped,
  detailPanel,
  detailPanelIcon,
  ...props
}) {
  const createSortHandler = (field) => (event) => {
    onSortChange(event, field)
  }

  const getRowProps = (rowData = null) => ({
    groupBy,
    columns,
    rowData,
    data,
    cellStyle,
    rowStyle,
  })

  function componentWithRowProps(Component, componentProps = {}) {
    if (!Component) return null

    const props = {
      ...getRowProps(),
      ...componentProps,
    }

    return typeof Component === 'function' ? Component(props) : null
  }

  // const Loader = componentWithRowProps(components.Loader)
  const Header = componentWithRowProps(components.Header)
  const Footer = componentWithRowProps(components.Footer, {
    isFooterRow: true,
    reportTitle: 'TOTAL GERAL',
  })

  const Group = (rowData) =>
    componentWithRowProps(components.Group, { rowData, isGroupRow: true })

  const GroupFooter = (rowData) => {
    const group = data.filter((item) => item[groupBy] === rowData[groupBy])

    return componentWithRowProps(components.GroupFooter, {
      rowData,
      reportTitle: 'SUB TOTAL',
      isGroupRow: true,
      data: [...group],
    })
  }

  function createTableData(data = []) {
    let rows = [...data]
    const grouping = Boolean(groupBy)

    // aplly grouping
    if (grouping) {
      const gLens = extractLens(groupBy)
      const parts = groupWith((a, b) => equals(gLens(a), gLens(b)), rows)

      const groups = [...parts].map((part) =>
        part.map((item, index) => {
          const lastIndex = part.length - 1

          switch (index) {
            case 0:
              return { ...item, isGroupRow: true }
            case lastIndex:
              return {
                ...item,
                isGroupFooterRow: true,
                isGroupRow: lastIndex === 0,
              }
            default:
              return { ...item }
          }
        })
      )

      rows = groups.flat()
    }

    const rowsWithProps = [...rows].map((rowData) => ({
      ...getRowProps(rowData),
    }))

    return rowsWithProps
  }

  const isDetailed = detailPanel !== undefined

  return (
    <Container
      {...props}
      className={clsx({ loading, emptyData: data.length === 0 })}
    >
      {/* {loading && (components.Loader ? Loader : <TableSpinner />)} */}
      <StyledTable size={props.size}>
        <TableHead>
          {components.Header && (
            <TableRow className="tableCustomHeader">
              <TableCell colSpan={columns.length}>{Header}</TableCell>
            </TableRow>
          )}
          {groupHeader && (
            <TableRow className="groupHeader">
              {groupHeader.map((group, groupIndex) => (
                <React.Fragment key={groupIndex}>
                  <TableCell
                    colSpan={group.start - 1}
                    style={{ background: colors.white }}
                  />
                  <TableCell align="center" colSpan={group.expand}>
                    {group.label}
                  </TableCell>
                </React.Fragment>
              ))}
            </TableRow>
          )}
          <TableRow>
            {isDetailed && <TableCell role="action" key="detail-panel-head" />}
            {columns.map((column, columnIndex) => (
              <TableCell
                role={column.role}
                align={column.align}
                key={String(columnIndex)}
                style={
                  cellStyle
                    ? cellStyle({
                        rowData: {},
                        isGroupRow: false,
                        isFooterRow: false,
                        isGroupedRow: false,
                        columnName: column.field,
                        columnIndex,
                      })
                    : {}
                }
              >
                {currentSort &&
                column.role !== 'button' &&
                column.sortable !== false ? (
                  <TableSortLabel
                    active={currentSort.field === column.field}
                    onClick={createSortHandler(column.field)}
                    direction={
                      currentSort.field === column.field
                        ? currentSort.order
                        : 'asc'
                    }
                  >
                    {column.title}
                  </TableSortLabel>
                ) : (
                  column.title
                )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody className={clsx({ 'TableBody-stripped': stripped })}>
          {isEmpty(data) ? (
            <TableNoData empty loading={loading} colSpan={columns.length + 1} />
          ) : (
            createTableData(data).map((rowProps, key) => {
              const isGroupRow = rowProps.rowData.isGroupRow
              const isGroupFooterRow = rowProps.rowData.isGroupFooterRow

              if (isGroupFooterRow) {
                return (
                  <React.Fragment key={key}>
                    <RenderTableRow
                      detailPanel={detailPanel}
                      detailPanelIcon={detailPanelIcon}
                      {...rowProps}
                    />
                    {GroupFooter(rowProps.rowData)}
                  </React.Fragment>
                )
              } else if (isGroupRow) {
                return (
                  <React.Fragment key={key}>
                    {Group(rowProps.rowData)}
                    <RenderTableRow
                      detailPanel={detailPanel}
                      detailPanelIcon={detailPanelIcon}
                      {...rowProps}
                    />
                  </React.Fragment>
                )
              } else
                return (
                  <RenderTableRow
                    key={key}
                    detailPanel={detailPanel}
                    detailPanelIcon={detailPanelIcon}
                    {...rowProps}
                  />
                )
            })
          )}
        </TableBody>
        {components.Footer && <TableFooter>{Footer}</TableFooter>}
      </StyledTable>
    </Container>
  )
}

const TranslateStringType = PropTypes.oneOfType([
  PropTypes.string,
  PropTypes.object,
])

const RefComponent = PropTypes.shape({ current: PropTypes.element })

const StyledComponent = PropTypes.shape({
  classes: PropTypes.object,
  innerRef: RefComponent,
})

const TableComponentType = PropTypes.oneOfType([
  PropTypes.func,
  StyledComponent,
])

const ColumnsType = PropTypes.arrayOf(
  PropTypes.shape({
    field: PropTypes.string,
    role: PropTypes.string,
    title: TranslateStringType,
    align: PropTypes.string,
    renderCell: PropTypes.func,
    colSpan: PropTypes.number,
  })
)

Table.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  columns: ColumnsType.isRequired,
  components: PropTypes.shape({
    Header: TableComponentType,
    Group: TableComponentType,
    Footer: TableComponentType,
  }),
  groupHeader: PropTypes.arrayOf(
    PropTypes.exact({
      label: PropTypes.string,
      start: PropTypes.number,
      expand: PropTypes.number,
    })
  ),
  cellStyle: PropTypes.func,
  rowStyle: PropTypes.func,
  loading: PropTypes.bool,
  currentSort: PropTypes.exact({
    field: PropTypes.string,
    order: PropTypes.oneOf(['asc', 'desc']),
  }),
  onSortChange: PropTypes.func,
  stripped: PropTypes.bool,

  detailPanel: PropTypes.oneOfType([PropTypes.func]),
  detailPanelIcon: PropTypes.oneOfType([PropTypes.func]),
}

Table.defaultProps = {
  components: {},
  size: 'small',
  loading: false,
  stripped: true,
}

export * from './styles'
export { Table, RenderTableRow }
export default Table
