import styled from 'styled-components'

// ------------------------------------------------------------------------

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  padding-bottom: 1rem;
  border-bottom: 1px solid ${(props) => props.theme.palette.divider};
`

export const ButtonsContainer = styled.div`
  display: flex;
  flex: 1;
  gap: 0.5rem;

  .MuiButton-root.reopen {
    background-color: ${({ theme }) => theme.palette.success.main};
  }

  .MuiButton-root.print {
    background-color: ${({ theme }) => theme.palette.info.main};
  }

  .MuiButton-root.cancel {
    background-color: ${({ theme }) => theme.palette.error.light};
  }
`

export const SummaryBlockContainer = styled.div`
  display: flex;
  flex-direction: column;

  .MuiTypography-body2 {
    font-size: 0.65rem;
  }
`
